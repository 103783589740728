(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/analytics/assets/javascripts/trackanalytics.js') >= 0) return;  svs.modules.push('/components/core/analytics/assets/javascripts/trackanalytics.js');
"use strict";


(function (svs, _svs$core$target) {
  'use strict';

  svs.core = svs.core || {};
  svs.core.analytics = svs.core.analytics || {};
  svs.core.analytics.adl = svs.core.analytics.adl || {};
  const log = new svs.core.log.Logger('svs.core.analytics');

  window.dataLayerPush({
    event: 'OneTrustGroups',
    optanonActiveGroups: svs.core.analytics.data.svsanalytics.optanonActiveGroups
  });
  window.dataLayerPush(svs.core.analytics.data.svsanalytics.user);
  window.dataLayerPush({
    event: 'pageview',
    page: {
      pageInfo: {
        pageID: document.location.pathname,
        pageName: document.title,
        destinationURL: document.location.pathname + document.location.search,
        sysEnv: svs.core.analytics.data.svsanalytics.formFactor
      },
      webPropertyId: svs.core.analytics.data.svsanalytics.config.account
    }
  });
  let analytics = false;
  let personalized = false;
  if (svs.core.config.data.svsconfig.oneTrust.enabled) {
    const consent = svs.core.analytics.data.svsanalytics.optanonConsent;
    analytics = (consent === null || consent === void 0 ? void 0 : consent.performance) || false;
    personalized = (consent === null || consent === void 0 ? void 0 : consent.personalization) || false;
  }

  svs.core.analytics.adl.consent = {
    analyticsBrowser: analytics,
    personalizedBrowser: personalized,
    QualtricsOk: svs.core.analytics.data.svsanalytics.qualtrics
  };
  svs.core.analytics.adl.user = {
    loggedIn: svs.core.analytics.data.svsanalytics.adobeUser.loggedIn || 'no',
    role: svs.core.analytics.data.svsanalytics.adobeUser.role || '',
    userId: svs.core.analytics.data.svsanalytics.adobeUser.userId || '',
    devId: svs.core.analytics.data.svsanalytics.adobeUser.devId || ''
  };
  svs.core.analytics.adl.site = {
    name: svs.core.analytics.data.svsanalytics.adobeLaunch.site,
    formFactor: svs.core.analytics.data.svsanalytics.formFactor,
    suite: svs.core.analytics.data.svsanalytics.adobeLaunch.suite,
    hostname: location.host
  };
  svs.core.analytics.adl.page = {
    name: document.location.pathname.substring(1).replace(/\//g, ':'),
    url: location.pathname + location.search
  };
  window.adobeDataLayer.adlPush('pageView', {
    site: svs.core.analytics.adl.site,
    consent: svs.core.analytics.adl.consent,
    user: svs.core.analytics.adl.user,
    page: svs.core.analytics.adl.page
  });
  window.adobeDataLayer.adlPush('user', {
    site: svs.core.analytics.adl.site,
    consent: svs.core.analytics.adl.consent,
    user: svs.core.analytics.adl.user,
    page: svs.core.analytics.adl.page
  });
  if ((_svs$core$target = svs.core.target) !== null && _svs$core$target !== void 0 && _svs$core$target.data) {
    var _svs$core$target$data;
    const targetPayloadMsg = new svs.core.log.Message(svs.core.log.MessageType.targetPayload);
    targetPayloadMsg.pe = svs.core.target.data.viewPayload.pe;
    targetPayloadMsg.tnta = svs.core.target.data.viewPayload.tnta;
    if ((_svs$core$target$data = svs.core.target.data) !== null && _svs$core$target$data !== void 0 && _svs$core$target$data.payload) {
      window.adobeDataLayer.adlPush('targetPayloadView', {
        site: svs.core.analytics.adl.site,
        consent: svs.core.analytics.adl.consent,
        user: svs.core.analytics.adl.user,
        page: svs.core.analytics.adl.page,
        payload: svs.core.target.data.payload
      });
    }
    svs.core.log.track('svs.core.analytics', targetPayloadMsg);
  }
  const metric = new svs.core.log.Message(svs.core.log.MessageType.pageview);
  svs.core.log.track('svs.core.analytics', metric);
  function sleep(delay) {
    return new Promise(resolve => {
      setTimeout(resolve, delay);
    });
  }

  async function trackAdobeUser(declaredId) {
    if (!declaredId) {
      log.warn("svs.core.analytics.trackAdobeUser was called without a declaredId");
      return;
    }
    const adobeUser = {
      loggedIn: 'yes',
      role: 'Player',
      userId: declaredId
    };
    svs.core.analytics.adl.user = {
      loggedIn: adobeUser.loggedIn || 'no',
      role: adobeUser.role || '',
      userId: adobeUser.userId || '',
      devId: svs.core.analytics.data.svsanalytics.adobeUser.devId || ''
    };
    window.adobeDataLayer.adlPush('user', {
      site: svs.core.analytics.adl.site,
      consent: svs.core.analytics.adl.consent,
      user: svs.core.analytics.adl.user,
      page: svs.core.analytics.adl.page
    });

    await sleep(100);
  }
  svs.core.analytics.trackAdobeUser = trackAdobeUser;
  function testPerformance(measurements) {
    let perf = window.performance || window.webkitPerformance;
    perf = perf && perf.timing;
    if (perf && perf.navigationStart !== 0) {
      const navstart = perf.navigationStart;
      measurements.totalPageLoadTime = perf.loadEventStart - navstart;
      measurements.serverResponseTime = perf.responseStart - perf.requestStart;
      measurements.pageDownloadTime = perf.responseEnd - perf.responseStart;
      measurements.pageInteractive = perf.domInteractive - navstart;
    }
  }
  function pageTimingsTest() {
    let sendLog = true;
    let perf = window.performance || window.webkitPerformance;
    perf = perf && perf.timing;
    if (perf) {
      if (perf.loadEventEnd < 1) {
        setTimeout(() => {
          pageTimingsTest();
        }, 200);
      } else {
        const sampleRate = parseInt(svs.core.analytics.data.svsanalytics.config.siteSpeedSampleRate, 10);
        const sampleNumber = Math.floor(Math.random() * (100 + 1));
        if (sampleNumber <= sampleRate) {
          const logdata = {};
          testPerformance(logdata);
          if (!$.isEmptyObject(logdata)) {
            $.each(logdata, (key, value) => {
              if (!parseInt(value, 10)) {
                sendLog = false;
              }
            });
            if (sendLog) {
              const timings = new svs.core.log.Message(svs.core.log.MessageType.timing);
              timings.totalPageLoadTime = logdata.totalPageLoadTime;
              timings.serverResponseTime = logdata.serverResponseTime;
              timings.pageDownloadTime = logdata.pageDownloadTime;
              timings.interactivePage = logdata.pageInteractive;
              svs.core.log.track('svs.core.analytics', timings);
            }
          }
        }
      }
    }
  }
  svs.core.analytics.timingTest = pageTimingsTest;
  $(document).ready(() => {
    pageTimingsTest();
  });
})(svs);

 })(window);